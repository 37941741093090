import React from 'react';
import CultureFrame from '@components/organisms/CultureFrame';
// layers
import LayerTop from '@components/assets/Culture/FR/LayerTop';
import LayerBottom from '@components/assets/Culture/FR/LayerBottom';
import LayerMouse from '@components/assets/Culture/FR/LayerMouse';

// components
import Layout from '@src/layouts';

// --------------------------------
// #region data
// --------------------------------

const pageClass = 'page-culture';

const language = 'fr';

const footerRelatedLinks = [
	{ title: 'Contact', url: '/fr/contact/' },
	{ title: 'Rejoindre', url: '/fr/rejoindre/' },
];

// --------------------------------
// #endregion
// --------------------------------

const CulturePage = (props) => {
	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/culture/', lang: 'en' }]}
			title="Notre culture - Superhuit"
			type="culture"
		>
			<div className="visually-hidden">
				<h1>Culture</h1>
				<h2>1. L'humain est central</h2>
				<p>Le succès se construit dans un environnement sain et bienveillant où chacun·e est autonome.</p>
				<h2>2. Libérer la créativité</h2>
				<p>La meilleure idée peut venir de n'importe qui. Pas besoin d'avoir l'étiquette du créatif !</p>
				<h2>3. Lundi, mon amour</h2>
				<p>La passion est notre moteur. Si elle vient à disparaitre, cela signifie que quelque chose doit être réparé.</p>
				<h2>4. Secouez-moi !</h2>
				<p>Le statu quo existe pour être questionné, car le changement n'est pas négatif par nature.</p>
				<h2>5. Force collective</h2>
				<p>L'intelligence collective nous conduit vers une progression continue. C'est la clé de notre succès !</p>
				<h2>6. Zéro secret</h2>
				<p>La transparence facilite un leadership partagé et génère de meilleures décisions.</p>
				<h2>7. Digne de confiance</h2>
				<p>La confiance multiplie les talents; elle n'est pas gagnée, elle est donnée.</p>
				<h2>8. Risquer l'innovation</h2>
				<p>Le progrès, l’innovation et la croissance demandent de prendre des risques. Leur recherche nous fait vibrer.</p>
			</div>
			<CultureFrame
				top={<LayerTop />}
				bottom={<LayerBottom />}
				mouse={<LayerMouse />}
				hint={
					<>
						Explorez avec
						<br />
						le cercle
					</>
				}
			/>
		</Layout>
	);
};

export default CulturePage;
